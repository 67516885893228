import Card from '@kissui/components/src/card'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'
import { stringifyForAttribute } from '@kissui/helpers/src/utils'

class CardLifestyle extends Card {
    constructor() {
        super()
        this.classList.add('nb-card-lifestyle')
    }

    setContrast() {
        const { background_color } = this.props.layout
        let contrast = 'light'
        if (background_color === 'festive-blue' || background_color === 'festive-red') {
            contrast = 'dark'
        }
        this.contrast = contrast
        this.setAttribute('contrast', this.contrast)
    }

    renderDefault() {
        const { cta = {} } = this.props.copywriting
        this.applyLayoutSettings()

        return `<div class="nb-card-lifestyle--content">
                    ${this.renderHeading()}
                    ${this.renderText()}
                    ${this.renderActions()}
                    ${this.renderPopin()}
                    ${cta.show_popin ? this.renderPopin('popin_cta') : ''}
                </div>
                ${this.renderVisual()}`
    }

    renderTablet() {
        const { cta = {} } = this.props.copywriting

        this.applyLayoutSettings()

        return `${this.renderVisual()}
                <div class="nb-card__tabletRightCol nb-card-lifestyle--content">
                    ${this.renderHeading()}
                    ${this.renderText()}
                    ${this.renderActions()}
                    ${this.renderPopin()}
                    ${cta.show_popin ? this.renderPopin('popin_cta') : ''}
                </div>`
    }

    renderCta() {
        const { cta = {}, popin_cta = {} } = this.props.copywriting
        const { label = '' } = cta
        const { id = '' } = popin_cta
        const instanceIndex = !isNaN(this.props.instanceIndex)
            ? this.props.instanceIndex.toString()
            : ''

        if (label === '') {
            return ''
        }

        const { campaign } = this.props
        if (campaign) {
            const { id, name, creative, position } = campaign
            cta.campaign_id = id
            cta.campaign_name = name
            cta.campaign_creative = creative
            cta.campaign_position = position
            cta.campaign_instance_index = instanceIndex
        }

        const data = stringifyForAttribute(cta)

        return `<nb-cta ${
            cta.show_popin ? `popin_id="${id}"` : ''
        } class="nb-card__cta" data="${data}">${label}</nb-cta>`
    }

    applyClassHelpers() {
        const heading = this.querySelector('.nb-card__heading')
        const body = this.querySelector('.nb-card__body')
        const dataAttribute = this.getAttribute('data')
        const data = JSON.parse(dataAttribute)
        const totalCards = parseInt(data.totalCards)
        let headingClassName

        if ((viewportHelper.is.desktop || viewportHelper.is.retina) && totalCards <= 2) {
            headingClassName = 'h-2xl-300'
        } else {
            headingClassName = 'h-xl-300'
        }

        if (heading) {
            this.querySelector('.nb-card__heading').classList.add(headingClassName)
        }

        if (body) {
            this.querySelector('.nb-card__body').classList.add('t-sm-400')
        }
    }

    applyLayoutSettings() {
        const { background_color = '', image_position } = this.props.layout
        if (background_color !== '') {
            this.classList.add(`nb-card-lifestyle--${background_color}`)
        }

        if (image_position === 'reverse') {
            this.classList.add('nb-card-lifestyle-reverse')
        }
    }
}

customElements.get('nb-card-lifestyle') || customElements.define('nb-card-lifestyle', CardLifestyle)

export default CardLifestyle
