import { stringifyForAttribute } from '@kissui/helpers/src/utils'
import createProps from '@kissui/helpers/src/props.helpers'

import '@kissui/components/src/card-lifestyle'
import '@kissui/components/src/layout-cards'

class CardHighlightedLifestyle extends HTMLElement {
    connectedCallback() {
        this.style.display = 'block'
        this.props = createProps(this.attributes)
        this.render()
    }

    render() {
        const data = stringifyForAttribute(this.props)
        this.innerHTML = `
            <nb-layout-cards data="${data}">
                ${this.renderCards()}
            </nb-layout-cards>
        `
    }

    renderCards() {
        const { cards = [] } = this.props

        const cardElements = cards.map(
            (card, currentIndex) => `
        <nb-card-lifestyle data="${stringifyForAttribute({
            ...card,
            instanceIndex: currentIndex,
            totalCards: cards.length
        })}"></nb-card-lifestyle>`
        )

        return cardElements.join('')
    }
}

customElements.get('nb-card-highlighted-lifestyle') ||
    customElements.define('nb-card-highlighted-lifestyle', CardHighlightedLifestyle)

export default CardHighlightedLifestyle
