import createProps from '@kissui/helpers/src/props.helpers'

class LayoutCards extends HTMLElement {
    constructor() {
        super()
        this.slot = this.innerHTML
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.classList.add('nb-layout-cards')
        this.render()
    }

    render() {
        const { layout } = this.props
        const slot = this.slot

        this.innerHTML = `
            <nb-container
                element_class="nb-layout-cards-inner"
                contrast="${layout.contrast}"
                background_color="${layout.background_color}"
                classname='${layout.padding_top} ${layout.padding_bottom}'
            >
              ${slot}
            </nb-container>
        `
    }
}

customElements.get('nb-layout-cards') || customElements.define('nb-layout-cards', LayoutCards)

export default LayoutCards
