import viewportHelper from './viewport.helpers'

const devices = ['mobile', 'tablet', 'desktop', 'retina'] as const
type Device = typeof devices[number]
type Sources =  { [P in Device]?: string; }

const getDevice = (sources: Sources) => {
    if (!Array.isArray(sources) && typeof sources !== 'object') {
        return null
    }

    return devices.find(
        device => viewportHelper.is[device] === true && device in sources && sources[device] !== ''
    )
}

const visual = () => {
    const getResponsiveSrc = (src: Sources) => {
        const device = getDevice(src)
        return device ? src[device] : src['desktop']
    }

    return { getResponsiveSrc }
}

const helper = visual()

export default helper
