import zoneTracking from '@kissui/helpers/src/assets/js/zoneTracking'
import { stringifyForAttribute } from '@kissui/helpers/src/utils'
import createProps from '@kissui/helpers/src/props.helpers'
import visualHelper from '@kissui/helpers/src/visual.helpers'
import viewportHelper from '@kissui/helpers/src/viewport.helpers'

class Card extends HTMLElement {
    constructor() {
        super()
        this.boundHandleClick = this.handleClick.bind(this)
    }

    connectedCallback() {
        this.props = createProps(this.attributes)
        this.render()
        this.initTracking()
    }

    setContrast() {
        const { contrast = 'light' } = this.props.layout
        this.contrast = contrast
        this.setAttribute('contrast', this.contrast)
    }

    createClasses() {
        const viewport = Object.keys(viewportHelper.is)
            .filter(k => viewportHelper.is[k])
            .join(' ')

        return ['nb-card', `nb-card--${this.contrast}`, `${viewport}`].join(' ')
    }

    createStyles() {
        const { background } = this.props.layout
        const responsiveSrc = background ? visualHelper.getResponsiveSrc(background) : ''

        return background ? `style="background-image: url('${responsiveSrc}')"` : ''
    }

    render() {
        this.setContrast()
        const content = viewportHelper.is.tablet ? this.renderTablet() : this.renderDefault()
        const containerClasses = this.createClasses()
        const containerStyles = this.createStyles()

        this.innerHTML = `<div class="${containerClasses}" ${containerStyles}>
                            ${content}
                         </div>`

        this.applyClassHelpers()
        this.cta = this.querySelector('.nb-card__cta')
        this.bindEvents()
    }

    applyClassHelpers() {}

    renderDefault() {
        return `${this.renderHeading()}
                ${this.renderText()}
                ${this.renderVisual()}
                ${this.renderActions()}
                ${this.renderPopin()}
               `
    }

    renderTablet() {
        return `${this.renderVisual()}
                <div class="nb-card__tabletRightCol">
                    ${this.renderHeading()}
                    ${this.renderText()}
                    ${this.renderActions()}
                    ${this.renderPopin()}
                </div>`
    }

    renderHeading() {
        const { heading = '' } = this.props.copywriting
        return heading && `<h2 class="nb-card__heading">${heading}</h2>`
    }

    renderText() {
        const { text = '' } = this.props.copywriting
        return text && `<p  class="nb-card__body">${text}</p>`
    }

    renderVisual() {
        const { src = '', alt = '' } = this.props.copywriting.visual
        // Warning! here we never did 2 image src fields in PB to manage a different tablet 1:1 image crop (for card-lifestyle)
        // (It was not possible as it shares its code with card-immersive...)
        // So to display the appropriate image quality we need to use force_width=560 (the original 19/6 image width) to load a bigger image in tablet
        // This bigger image will be displayed in a 1:1 layout, but sides cropped by css cover properties of img component
        return (
            src &&
            `<nb-img class="nb-card__visual"
                file="${src}"
                description="${alt}"
                aspect_ratio="${viewportHelper.is.tablet ? '1/1' : '16/9'}"
                force_width="${viewportHelper.is.tablet ? '560' : ''}"
            ></nb-img>`
        )
    }

    renderActions() {
        const { cta = {} } = this.props.copywriting
        const { label = '' } = cta
        const { copywriting: { popin = {} } = {} } = this.props
        const { text = '' } = popin

        if (label === '' && !text) {
            return ''
        }

        return `<div class='nb-card__actions'>
                    ${this.renderCta()}
                    ${this.renderLink()}
                </div>`
    }

    renderCta() {
        const { cta = {} } = this.props.copywriting
        const { label = '' } = cta
        const instanceIndex = !isNaN(this.props.instanceIndex)
            ? this.props.instanceIndex.toString()
            : ''

        if (label === '') {
            return ''
        }

        const { campaign } = this.props
        if (campaign) {
            const { id, name, creative, position } = campaign
            cta.campaign_id = id
            cta.campaign_name = name
            cta.campaign_creative = creative
            cta.campaign_position = position
            cta.campaign_instance_index = instanceIndex
        }

        const data = stringifyForAttribute(cta)

        return `<nb-cta class="nb-card__cta" data="${data}">${label}</nb-cta>`
    }

    renderLink() {
        const { copywriting: { popin = {} } = {}, campaign } = this.props
        const { id = '', text = '' } = popin
        const instanceIndex = !isNaN(this.props.instanceIndex)
            ? this.props.instanceIndex.toString()
            : ''

        if (!text) {
            return ''
        }
        let linkCampaignData = {}
        if (campaign) {
            const { id, name, creative, position } = campaign
            linkCampaignData.campaign_id = id
            linkCampaignData.campaign_name = name
            linkCampaignData.campaign_creative = creative
            linkCampaignData.campaign_position = position
            linkCampaignData.campaign_instance_index = instanceIndex
        }

        const data = stringifyForAttribute(linkCampaignData)

        return `
            <nb-link
                size="xsmall"
                color="${this.contrast === 'light' ? 'black' : 'white'}"
                popin_id="${id}"
                class="nb-card__link"
                data="${data}"
            >${text}</nb-link>
        `
    }

    generateList(list) {
        if (!Array.isArray(list) || list.length === 0) {
            return ''
        }

        const items = list
            .map(item => `<li><nb-icon icon="16/symbol/check"></nb-icon>${item.text}</li>`)
            .join('')
        return `<ul class="nb-card__popin-list">${items}</ul>`
    }

    renderPopin(schema_popin_id) {
        const { copywriting = {} } = this.props
        const popin = copywriting[schema_popin_id] || copywriting.popin
        const {
            id = '',
            image = '',
            heading = '',
            subheading = '',
            description = '',
            list = '',
            image_alt = '',
            close = ''
        } = popin || {}

        return (
            id &&
            `<nb-popin
                id="${id}"
                heading="${heading}"
                subheading="${subheading}"
                variation="next"
                bgcolor="highlight"
                label_close="${close}"
            >
                ${
                    image &&
                    `<div class="popin-header-img"><img
                        loading="lazy"
                        src="${image}"
                        alt="${image_alt}"
                        title="${image_alt}"
                        width="300"
                        height="225"
                    /></div>
                `
                }
                ${description}
                ${list ? this.generateList(list) : ''}
            </nb-popin>`
        )
    }

    initTracking() {
        const { campaign } = this.props
        if (!campaign) {
            return
        }

        const { id, name, creative, position } = campaign

        zoneTracking(this, { id, name, position, creative })
    }

    handleClick() {
        this.querySelector('.nb-card__cta > *').click()
    }

    bindEvents() {
        this.unbindEvents()
        if (this.cta) {
            const overlay = document.createElement('div')
            overlay.classList.add('nb-card__overlay')
            const actionsNode = this.querySelector('.nb-card__actions')
            const parentActionsNode = actionsNode.parentNode
            parentActionsNode.insertBefore(overlay, actionsNode)
            overlay.addEventListener('click', this.boundHandleClick)
        }
    }

    unbindEvents() {
        if (this.cta) {
            this.removeEventListener('click', this.boundHandleClick)
        }
    }

    disconnectedCallback() {
        this.unbindEvents()
    }
}

customElements.get('nb-card') || customElements.define('nb-card', Card)

export default Card
